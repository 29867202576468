import { useEffect, useState } from "react";

import { checkIsDesktop } from "../utils/checkIsDesktop.ts";
import { checkUuidVal } from "../reducers/analytics.ts";
import { initFlagsmith } from "../utils/flags.ts";
import { logErr } from "../utils/logs.ts";
import { selectAnalyticsUuid } from "../selectors/analytics.ts";
import useFetchGeoLocation from "./useFetchGeoLocation.ts";
import { useSelector } from "react-redux";

export default function useInitialiseFlagsmith(
  isExperimentUser: boolean,
  disableUpFrontPayment: boolean,
  swimlane: string | undefined,
): boolean {
  const [flagsmithReady, setFlagsmithReady] = useState(false);
  const analyticsUuid = useSelector(selectAnalyticsUuid);
  const { data: geoData, isError: geoError } = useFetchGeoLocation();
  const countryCode = geoData?.location?.countryCode;
  const currencyCode = geoData?.meta?.currency;
  const params = new URLSearchParams(window.location.search);
  const landingPageVariant = params.get("lpe");

  useEffect(() => {
    const initialiseFlagsmith = async () => {
      const isDesktop = checkIsDesktop();
      const traits = {
        "web-user": true,
        ...(countryCode ? { "country-code": countryCode } : {}),
        ...(currencyCode ? { "currency-code": currencyCode } : {}),
        ...(isExperimentUser ? { "experiment-user": true } : {}),
        ...(isDesktop ? { is_desktop: true } : {}),
        ...(disableUpFrontPayment ? { "up-front-payment-disabled": true } : {}),
        ...(swimlane ? { swimlane } : {}),
        ...(landingPageVariant != null
          ? { "landing-page-variant": landingPageVariant }
          : {}),
      };

      checkUuidVal(analyticsUuid, "useInitialiseFlagsmith");

      await initFlagsmith(analyticsUuid, traits)
        .catch((e) => logErr("Failed to initialise Flagsmith", e))
        .finally(() => setFlagsmithReady(true));
    };

    if (geoData || geoError) initialiseFlagsmith();
  }, [
    analyticsUuid,
    countryCode,
    currencyCode,
    disableUpFrontPayment,
    geoData,
    geoError,
    isExperimentUser,
    swimlane,
  ]);

  return flagsmithReady;
}
